import EditableCodeBlock from "@components/editable-code-block";
import requestJson from "./request.json";
import responseJson from "./response.json";
import { useState, useEffect } from "react";
import { LoadingSpinner } from "@pages/sign-in/login-form";
import { Button } from "@components/ui/Button";
import { api } from "@lib/api/client";

type IHomeCodeBlockProps = {
  apiKey?: string;
  fromHomePage?: boolean;
};

export default function HomeCodeBlock(props: IHomeCodeBlockProps) {
  const API_KEY = props?.apiKey;
  const [editableBody, setEditableBody] = useState(
    JSON.stringify(requestJson, null, 2)
  );
  const [response, setResponse] = useState<{
    status: string | number;
    responseJson: any;
  }>({ status: "", responseJson: null });
  const [showRequest, setShowRequest] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [requestCount, setRequestCount] = useState(0);

  useEffect(() => {
    const count: number = parseInt(
      sessionStorage.getItem("codeBlock_Requests") || "0"
    );
    setRequestCount(count);
  }, []);

  const submitRequest = async () => {
    let count: number = parseInt(
      sessionStorage.getItem("codeBlock_Requests") || "0"
    );
    let requestBody = editableBody ? editableBody : requestJson;

    const input =
      typeof requestBody === "string"
        ? requestBody
        : JSON.stringify(requestBody);

    try {
      setIsLoading(true);

      const res = await api.dashboard.homeCodeBlock.mutate(JSON.parse(input));
      setResponse({ responseJson: res.value, status: res.status });
      sessionStorage.setItem("codeBlock_Requests", String(count + 1));
      setRequestCount(count + 1);
    } catch (err) {
      console.error("Error:", err);
      setResponse({ responseJson: "Error getting response", status: 500 });
    } finally {
      setShowRequest(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="relative justify-end w-full flex">
        <div className="flex justify-center md:justify-end w-[100%] h-[500px] text-base leading-relaxed text-blue-400 bg-transparent rounded-md shadow-lg xl:text-lg pr-0">
          {showRequest && (
            <EditableCodeBlock
              className="text-sm rounded-md max-w-[100%]"
              language="json"
              code={JSON.stringify(requestJson, null, 2)}
              isEditable={true}
              colorWhite={false}
              setEditableBody={(content) => setEditableBody(content)}
              setResponse={(content) => setResponse(content)}
              fromHomePage={props?.fromHomePage}
            />
          )}
          {!showRequest && (
            <EditableCodeBlock
              colorWhite={false}
              fromHomePage={props?.fromHomePage}
              className="text-sm rounded-md max-w-[100%]"
              response={response}
              language="json"
              code={
                response.responseJson
                  ? JSON.stringify(response.responseJson, null, 2)
                  : JSON.stringify(responseJson, null, 2)
              }
            />
          )}
        </div>
      </div>
      <div className="flex gap-2">
        {requestCount <= 3 && !(response?.status?.toString()?.length > 0) ? (
          <Button
            btnType="gradient"
            className=" gap-px mt-6 w-fit"
            onClick={submitRequest}
            aria-label={isLoading ? "Loading" : "Run Toll API"}
            // disabled={!editableBody || !editableBody.trim()}
          >
            {isLoading ? <LoadingSpinner /> : "Run Toll API"}
          </Button>
        ) : (
          <Button
            onClick={() =>
              (window.location.href =
                "/toll-api-docs?requestEditable=true#tolls-between-origin-destination-and-waypoints")
            }
            // className="focus-visible:shadow-outline-indigo px-5 py-2 rounded-full bg-gradient-to-t from-tollguru-primary to-tollguru-secondary text-white hover:bg-tollguru-secondary disabled:opacity-40 inline-flex items-center group gap-px mt-6 w-fit"
            btnType="gradient"
            className=" gap-px mt-6 w-fit">
            <span>Go to Toll API Playground</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-4 h-4 mt-px group-hover:translate-x-1 transition-transform">
              <path
                fillRule="evenodd"
                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                clipRule="evenodd"></path>
            </svg>
          </Button>
        )}
      </div>
    </>
  );
}
